/* eslint eqeqeq: 0 */
import { Box, Button, Typography, Dialog, Stack } from "@mui/material";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import firebaseConfig from "../../../firebase/config";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import "intl-tel-input/build/css/intlTelInput.css";
import { NavLink } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import api from "../../../API/FetchApi";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBookmark } from "../../../redux/Bookmark";
import { updateCartItems } from "../../../redux/cart";
import { setAddress } from "../../../redux/UserAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setProfile } from "../../../redux/UserData";
import { handleForce } from "../../../redux/Login";
import { handleAuth } from "../../../redux/authentication";
import RegisterUser from "../../Reusable/Profile/RegisterUser";
import { parsePhoneNumber } from 'libphonenumber-js';

const Authentication = ({ login, isLogin, setIsloggedIn }) => {
  const settings = useSelector((state) => state.Settings).settings;
  const country_dail_code = process.env.REACT_APP_DIAL_CODE;
  const country_code = process.env.REACT_APP_COUNTRY_CODE;
  // let general_settings = settings?.general_settings;
  const mode = settings?.general_settings?.demo_mode;
  // let country_code = general_settings?.country_code;
  //TODO: might use later.
  const initialBackdrop = false;
  const initialshowOtp = false;
  const [otp, setOtp] = useState(mode === "0" ? "" : "123456");
  const [ph, setPh] = useState(mode === "0" ? "" : "919876543210");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(initialshowOtp);
  const theme = useTheme();
  const [isMenuBackdropOpen, setIsMenuBackdropOpen] = useState(initialBackdrop);
  const [addUserDetails, setAddUserDetails] = useState(false);
  const [verification, setVerification] = useState(0);
  const firebase = firebaseConfig();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.UserData);
  useEffect(() => {
    window.recaptchaVerifier = new firebase.firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }, [firebase.firebase.auth]);

  const [selectedCountryCode, setSelectedCountryCode] =
    useState(country_dail_code);

  // Event handler to update the selected country code state
  const handlePhoneChange = (value) => {
    setPh(value);
    if (value) {
      try {
        const phoneNumberObject = parsePhoneNumber(value);
        setSelectedCountryCode(phoneNumberObject.countryCallingCode);
      } catch (error) {
        if (error.name === 'ParseError') {
          setSelectedCountryCode('');
        }
      }
    } else {
      setSelectedCountryCode('');
    }
  };

  const formatNumber = () => {
    if (ph) {
      try {
        const phoneNumberObject = parsePhoneNumber(ph);
        // Remove country code from the phone number
        const phoneNumber = phoneNumberObject.nationalNumber;
        return phoneNumber;
      } catch (error) {
        if (error.name === 'ParseError') {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  const handleClose = useCallback(() => {
    isLogin(false);
    setShowOTP(initialshowOtp);
    setIsMenuBackdropOpen(initialBackdrop);
    setAddUserDetails(false);
    dispatch(handleForce(true));
  }, []);

  // Function for SignUP
  const onSignup = useCallback(async () => {
    setLoading(true);
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = ph;
    const confirmationResult = await firebase.auth.signInWithPhoneNumber(
      phoneNumber,
      appVerifier
    );
    try {
      console.log("err1");
      if (confirmationResult) {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        isLogin(false);
        toast.success("OTP sent successfully!");
        return;
      }
    } catch (error) {
      console.log("err", error.code);
      switch (error.code) {
        case "auth/invalid-phone-number":
          toast.error("Invalid phone number format.");
          break;
        case "auth/missing-phone-number":
          toast.error("Phone number is required.");
          break;
        default:
          toast.error("An error occurred. Please try again later.");
          break;
      }
      setLoading(false);
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaVerifier.reset(widgetId);
      });
      return;
    }
  }, [firebase.auth,ph]);

  const verifyUser = useCallback(async (res) => {
    try {
      const formattedPhoneNumber = formatNumber();
      const response = await api.VerifyUser({
        phone: formattedPhoneNumber,
        country_code: "+" + selectedCountryCode,
        uid: res.user.uid,
      });

      switch (response.message_code) {
        case "101":
          setVerification(1);
          await getToken(res);
          break;
        case "102":
          setVerification(0);
          setIsMenuBackdropOpen(true);
          setAddUserDetails(true);
          const result = await api.getToken({
            mobile: formattedPhoneNumber,
            country: "+" + selectedCountryCode,
            web_fcm_id: profile.web_fcm_token,
            uid: res.user.uid,
            loginType: "phone",
          });
          dispatch(setProfile(result));
          localStorage.setItem("ContactInfo", formattedPhoneNumber);
          localStorage.setItem("Token", result.token);
          break;
        case "103":
          setVerification(0);
          toast.error(
            "Your account is Deactivated. Please contact admin for more information"
          );
          handleClose();
          break;
        default:
          setVerification(1);
          await getToken(res);
      }
    } catch (error) {
      console.log("error", error);
      console.error("Verify User Error:", error);
      // Handle error appropriately, e.g., show an error message to the user
    }
  }, [ph, selectedCountryCode, dispatch, handleClose]);

  //function to getting token when user logged in
  const getToken = useCallback(async (res) => {
    try {
      const formattedPhoneNumber = formatNumber();
      const response = await api.getToken({
        mobile: formattedPhoneNumber,
        country: "+" + selectedCountryCode,
        web_fcm_id: profile.web_fcm_token,
        loginType: "phone",
        uid: res.user.uid,
      });

      localStorage.setItem("ContactInfo", formattedPhoneNumber);
      localStorage.setItem("isLoggedIn", true);

      isLogin(false); // Assuming this updates a state variable
      setIsloggedIn("true"); // Assuming this updates a state variable

      const { lat, lng } = localStorage; // Destructuring for concise variable access

      localStorage.setItem("Token", response.token);
      dispatch(setProfile(response));
      dispatch(handleAuth(true));
      toast.success(response.message);

      const formdata = new FormData();
      formdata.append("type", "list");
      formdata.append("latitude", lat);
      formdata.append("longitude", lng);

      if (verification === 1) {
        try {
          const bookmarksResponse = await api.get_bookmarks(formdata);
          if (!bookmarksResponse.error) {
            const markedArray = bookmarksResponse.data.map((item, index) => ({
              id: index + 1,
              partner_id: item.partner_id,
            }));
            dispatch(setBookmark(markedArray));
          }
        } catch (error) {
          console.error("Error fetching bookmarks:", error);
        }

        try {
          const cartResponse = await api.get_cart();
          localStorage.setItem("cartItems", JSON.stringify(cartResponse));
          dispatch(updateCartItems(cartResponse.data));
        } catch (error) {
          console.error("Error fetching cart items:", error);
        }

        try {
          const addressResponse = await api.getAddress();
          dispatch(setAddress(addressResponse.data));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      }
    } catch (error) {
      console.error("Error getting token:", error);
    }
  }, [
    ph,
    dispatch,
    isLogin,
    selectedCountryCode,
    verification,
  ]);

  //Function for Otp Verification
  const onOTPVerify = useCallback(async () => {
    if (otp === "") {
      toast.error("Please enter the verification code!");
      return;
    }

    setLoading(true);

    try {
      const res = await window.confirmationResult.confirm(otp);
      if (res.user) {
        setLoading(false);
        await verifyUser(res);
      } else {
        window.recaptchaVerifier.render().then((widgetId) => {
          window.recaptchaVerifier.reset(widgetId);
        });
        setLoading(false);
        toast.error("Invalid verification code. Please try again!");
      }
    } catch (error) {
      switch (error.code) {
        case "INVALID_CODE":
          toast.error("Invalid Code");
          break;
        case "auth/invalid-verification-code":
          toast.error("Invalid Verification Code");
          break;
        case 400:
          toast.error("To many request please try again later");
          break;
        case "auth/too-many-requests":
          toast.error(
            "Have blocked all requests from this device due to unusual activity. Try again later."
          );
          break;
        // Add more cases for other error types if needed
        default:
          toast.error("An error occurred. Please try again later.");
          break;
      }

      setLoading(false);
    }
  }, [otp, verifyUser]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onOTPVerify();
    }
  };

  //Resend OTP
  const [resendTimer, setResendTimer] = useState(60);
  const [disableResend, setDisableResend] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const onResendOTP = async () => {
    try {
      await onSignup(); // Send OTP
      setDisableResend(true);

      const id = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      setIntervalId(id); // Store the intervalId in state

      setTimeout(() => {
        clearInterval(id);
        setResendTimer(60);
        setDisableResend(false);
      }, 60000);
    } catch (error) {
      console.error("Error sending OTP:", error);
      // Handle OTP sending error (e.g., display an error message to the user)
    }
  };

  useEffect(() => {
    // Clear the timer interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <Box>
      {/* Enter Mobile Number */}

      <Dialog
        sx={{
          color: "#fff",
        }}
        maxWidth="lg"
        open={login}
      >
        <Box
          sx={{
            color: "black",
            background: theme.palette.background.box,
            width: { xs: "100%", md: "100%" },
            textAlign: "center",
          }}
        >
          <Box
            marginLeft={3}
            maxWidth={"100%"}
            marginRight={3}
            marginTop={3}
            marginBottom={3}
          >
            <Box
              display={isMenuBackdropOpen ? "none" : "flex"}
              alignItems={"center"}
            >
              <Typography
                marginRight={"auto"}
                fontSize={20}
                color={theme.palette.color.textColor}
              >
                {t("login")}
              </Typography>
              {
                <ClearIcon
                  onClick={handleClose}
                  sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                  fontSize="large"
                />
              }
            </Box>

            <Typography
              sx={{
                color: theme.palette.color.navLink,
                letterSpacing: 1,
              }}
              marginBottom={2}
              fontSize={22}
              variant="h6"
            >
              {t("welcome")}
            </Typography>
            <Typography
              sx={{ color: theme.palette.color.navLink }}
              fontSize={14}
            >
              {t("send_verification_code")}
            </Typography>
            <Box
              sx={{
                mt: 5,
                mb: 2,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <PhoneInput
                defaultCountry={country_code}
                disabledCountryCode={true}
                countryCallingCodeEditable={true}
                international={true}
                value={ph}
                onChange={handlePhoneChange}
                inputStyle={{ width: "100%", height: "54px" }}
                className="custom-phone-input"// Update selected country code state
              />
            </Box>

            <Button
              onClick={onSignup}
              variant="contained"
              disableElevation
              size="large"
              fullWidth
              className="border-radius-2 lower-case-btn"
              sx={{ mb: 5, borderRadius: "8px" }}
              disabled={loading == true ? true : false}
            >
              {loading && <FontAwesomeIcon icon={faSpinner} spin />}
              <span>{t("login_to_continue")}</span>
            </Button>
            <Typography color={"gray"} fontSize={15}>
              {t("agree_to_out")}
            </Typography>
            <Typography
              fontSize={15}
              display={"flex"}
              justifyContent={"center"}
            >
              <NavLink
                style={{ color: theme.palette.primary.main }}
                to={"/terms-and-conditions"}
                onClick={() => isLogin(false)}
              >
                {t("terms")}
              </NavLink>
              &nbsp;
              <p style={{ color: theme.palette.color.navLink }}>&</p>
              &nbsp;
              <NavLink
                style={{ color: theme.palette.primary.main }}
                to={"/privacy-policies"}
                onClick={() => isLogin(false)}
              >
                {t("privacy")}
              </NavLink>
            </Typography>
          </Box>
        </Box>
      </Dialog>

      {/* Enter OTP */}

      <Dialog
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        maxWidth="lg"
        open={showOTP}
      >
        <Box
          sx={{
            color: "black",
            background: theme.palette.background.box,
            width: { xs: "100%", md: "100%" },
            textAlign: "center",
          }}
        >
          <Box
            marginLeft={3}
            maxWidth={"100%"}
            marginRight={3}
            marginTop={3}
            marginBottom={3}
            className="new-otp"
          >
            <Box
              display={isMenuBackdropOpen ? "none" : "flex"}
              alignItems={"center"}
            >
              <Typography
                marginRight={"auto"}
                fontSize={20}
                color={theme.palette.color.textColor}
              >
                {t("login")}
              </Typography>
              {
                <ClearIcon
                  onClick={handleClose}
                  sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                  fontSize="large"
                />
              }
            </Box>

            <Box mt={4}>
              <label
                htmlFor="otp"
                className="font-bold text-xl text-white text-center"
              >
                <Typography
                  sx={{
                    color: theme.palette.color.navLink,
                    letterSpacing: 1,
                  }}
                  variant="h6"
                  fontSize={22}
                >
                  {t("enter_verification_code")}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.color.secondary,
                    fontSize: 15,
                    mt: 1,
                  }}
                >
                  {t("sent_verification_code")}
                </Typography>
                <Typography color={theme.palette.primary.main} fontSize={15}>
                  {ph}
                </Typography>
              </label>
            </Box>
            <Box
              marginTop={5}
              mb={2}
              onKeyPress={handleKeyPress}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container"
              ></OtpInput>
            </Box>

            <Stack spacing={3}>
              <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.primary.main,
                    border: "1px solid gray",
                    textTransform: "none",
                    borderRadius: "8px",
                    width: "50%",
                  }}
                  onClick={onResendOTP}
                  disabled={disableResend}
                >
                  {disableResend ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      Resend OTP :
                      <Typography variant="subtitle2" color={"#0277FA"}>
                        {resendTimer}s
                      </Typography>
                    </Box>
                  ) : (
                    "Resend OTP"
                  )}
                </Button>
              </Box>

              <Button
                onClick={onOTPVerify}
                disabled={loading == true ? true : false}
              >
                <Button
                  variant="contained"
                  size="medium"
                  fullWidth
                  disabled={loading == true ? true : false}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    width: "100% !important",
                  }}
                >
                  {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                  {t("verify_and_process")}
                </Button>
              </Button>
            </Stack>
            {/* <Box sx={{ mt: 4, mb: 2, width: "100%" }} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                        </Box> */}
          </Box>
        </Box>
      </Dialog>

      {/* Edit Profile */}

      <Dialog
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={addUserDetails}
      >
        <Box
          sx={{
            background: theme.palette.background.box,
            color: "black",
            width: { xs: "100%", md: "400px" },
          }}
        >
          <Box
            marginLeft={3}
            maxWidth={"100%"}
            marginRight={3}
            marginTop={3}
            marginBottom={3}
          >
            <Box
              display={isMenuBackdropOpen ? "none" : "flex"}
              alignItems={"center"}
            >
              <Typography
                marginRight={"auto"}
                fontSize={20}
                color={theme.palette.color.textColor}
              >
                {t("sign_up")}
              </Typography>
              {
                <ClearIcon
                  onClick={handleClose}
                  sx={{ color: theme.palette.icons.icon, cursor: "pointer" }}
                  fontSize="large"
                />
              }
            </Box>

            <Box mt={4}>
              <RegisterUser mobile={ph} />
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Box id="recaptcha-container"></Box>
    </Box>
  );
};

export default Authentication;
